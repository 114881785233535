// import publicAxios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import createMutationsSharer from "vuex-shared-mutations"
import { db} from '@/plugins/firebase'
import {collection, getDocs, query, where } from "firebase/firestore"

Vue.use(Vuex)

export default async function () {
  
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  const usuario = localStorage.getItem('usuario') ? localStorage.getItem('usuario') : null
  const negocio = localStorage.getItem('negocio') ? localStorage.getItem('negocio') : null
  
  const getDefaultState = () => {
    return {
      token: token,
      usuario: usuario,
      negocio: negocio
    }
  }

  const store = new Vuex.Store({
    state: getDefaultState,
    mutations: {
      setNegocio (state, negocio) {
        state.negocio = negocio
      },
      setUsuario (state, usuario) {
        state.usuario = usuario
      },
      setToken (state, token) {
        state.token = token
      },
      RESET_STATE(state) {
        state.token = null
        state.usuario = null
      },
      updateData (state, dataDetails) {
        state.usuario.nombre = dataDetails.nombre
        state.usuario.avatar = dataDetails.avatar
      },
    },
    actions: {
      async setInfoNegocio ({ commit }, { dataDetails }) {
        commit("setNegocio", dataDetails)
        localStorage.setItem('negocio', negocio)
      },
      async setInfoUser ({ commit }, { data }) {
        commit("setUsuario", data)
        localStorage.setItem('usuario', usuario)
      },
      async login ({ commit }, { authDetails }) {
        const token = authDetails.uid
        const usuario = authDetails

        const rolQuery = query(collection(db, 'roles'), where("rol", "==", usuario.rol))
        const roles = await getDocs(rolQuery)
        usuario['rutas'] = []
        roles.forEach((rol) => {
          usuario['rutas'] = rol.data().rutas
        })

        commit("setUsuario", usuario)
        commit("setToken", token)
        localStorage.setItem('token', token)
        localStorage.setItem('usuario', usuario)
      },
      async updateProfile ({ commit }, { dataDetails }) {
        commit("updateData", dataDetails)
        localStorage.setItem('usuario', (state) => { return state.usuario} )
      },
      logout ({ commit }) {
        commit("RESET_STATE")
        localStorage.clear()
      }
    },
    getters: {
      aperturaCaja: (state) => {
        return state.usuario && state.usuario.aperturaCaja ? state.usuario.aperturaCaja : false
      },
      userId: (state) => {
        return state.usuario && state.usuario.uid ? state.usuario.uid : null
      },
      cajaId: (state) => {
        return state.usuario && state.usuario.cajaId ? state.usuario.cajaId : null
      },
      userName: (state) => {
        return state.usuario && state.usuario.nombre ? state.usuario.nombre : null
      },
      userRol: (state) => {
        return state.usuario && state.usuario.rol ? state.usuario.rol : null
      },
      user: (state) => {
        return state.usuario ? state.usuario : null
      },
      logeado: (state) => {
        return state.token ?  true : false
      },
      negocio: (state) => {
        return state.negocio ? state.negocio : null
      },
      logo: (state) => {
        return state.negocio && state.negocio.logoUrl ? state.negocio.logoUrl : '/no_image.png'
      }
    },
    plugins: [
      createPersistedState({
        storage: localStorage
      },
      createMutationsSharer({
        predicate: (mutation) => {
          const predicate = [
            "setNegocio",
            "setUsuario",
            "setToken",
            "RESET_STATE",
            "updateData"
          ]
          return predicate.indexOf(mutation.type) >= 0
        }
      })
    )]
  })
  return store
}
