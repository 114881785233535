import createStore from '@/store'

export default function log({ next, to }) {
  getDataUser()
  .then(dataUser => {
    if(!dataUser || !dataUser.rutas) {
      return next('/')
    }
    if(!dataUser.rutas.includes(to.name)) {
      return next('/dashboard')
    }
  })
  return next();
}

async function getDataUser() {
  let dataUser = null
  await createStore().then(store => {
    if(!dataUser)
      dataUser = store.getters.user
  })
  return dataUser
}