import Vue from 'vue'
import { getMMM, getYYYY, standardDate, standardTime, largeDate, completeDate, dateAgo, monthDay } from '../filters/fechas'
import { redondeaNumero, obtenerEntero, obtenerParteDecimal, valorMonetario } from '../filters/numericos'

Vue.filter('getMMM', getMMM)
Vue.filter('getYYYY', getYYYY)
Vue.filter('redondeaNumero', redondeaNumero)
Vue.filter('obtenerEntero', obtenerEntero)
Vue.filter('obtenerParteDecimal', obtenerParteDecimal)
Vue.filter('valorMonetario', valorMonetario)
Vue.filter('standardDate', standardDate)
Vue.filter('standardTime', standardTime)
Vue.filter('largeDate', largeDate)
Vue.filter('completeDate', completeDate)
Vue.filter('dateAgo', dateAgo)
Vue.filter('monthDay', monthDay)
