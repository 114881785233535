import Axios from 'axios'
import Vue from 'vue'
let baseURL = 'http://localhost:5000/'
Vue.prototype.$axiosPRINT = Axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin'
  }
})