import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import '@/sass/overrides.sass'
// import '@/sass/variables.scss'

Vue.use(Vuetify);

Vue.prototype.$vueify = Vuetify

export default new Vuetify({
  // breakpoint: {
  //   scrollBarWidth: 16,
  //   thresholds: {
  //     xs: 600,
  //     sm: 960,
  //     md: 1280,
  //     lg: 1920
  //   }
  // },
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    treeShake: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#03c9d7',
        secondary: '#fb9678',
        accent: '#82B1FF',
        error: '#e46a76',
        info: '#0bb2fb',
        success: '#00c292',
        warning: '#fec90f',
        backcolor: '#121F3D',
        blackcolor: '#03091E'
      }
    }
  },
  lang: {
    current: 'es',
    locales: { es },
  },
  icons: {
    iconfont: 'fa'
  },
});
