import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import createStore from '@/store'
import accessControl from '../middleware/accessControl';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/LoginView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/app/DashboardApp.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex',
    name: 'kardex',
    component: () => import('@/views/app/kardex/ItemKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingresos-egresos',
    name: 'ingresos-egresos',
    component: () => import('@/views/app/kardex/IngresosEgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('@/views/app/inventario/InventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/item/:id',
    name: 'item',
    component: () => import('@/views/app/inventario/ItemInventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detailitem/:id',
    name: 'detailitem',
    component: () => import('@/views/app/inventario/ItemDetailView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/barcodegenerator',
    name: 'barcodegenerator',
    component: () => import('@/views/app/inventario/BarCodeGeneratorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    component: () => import('@/views/app/proveedores/ProveedoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedor/:id',
    name: 'proveedor',
    component: () => import('@/views/app/proveedores/ItemProveedorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/app/UserProfile.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/app/GeneralConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol',
    name: 'rol',
    component: () => import('../views/app/roles/GestionRol.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol/asignar-ruta',
    name: 'rolRuta',
    component: () => import('../views/app/roles/AsignarRutas.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/app/users/ManageUsers.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users/form',
    name: 'usersForm',
    component: () => import('../views/app/users/FormView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('../views/app/clientes/ClientView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cliente/:id',
    name: 'cliente',
    component: () => import('@/views/app/clientes/ItemClienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/prints',
    name: 'prints',
    component: () => import('../views/app/PrintsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/app/inventario/InventarioStockView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/app/CategoriesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    component: () => import('../views/app/comprobantes/ComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobante',
    name: 'comprobante',
    component: () => import('../views/app/comprobantes/NewComprobanteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/gestionar-caja',
    name: 'gestionar-caja',
    component: () => import('../views/app/cajas/GestionarCajaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/:id',
    name: 'detalleCaja',
    component: () => import('../views/app/cajas/DetalleCajasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egresos-caja',
    name: 'egresos-caja',
    component: () => import('../views/app/cajas/EgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egreso-caja/:id',
    name: 'egreso-caja',
    component: () => import('../views/app/cajas/ItemEgresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/transacciones/:id',
    name: 'detalleTransacciones',
    component: () => import('../views/app/cajas/DetalleTransaccionesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/reportes-generales',
    name: 'reportes-generales',
    component: () => import('../views/app/reportes/ReportesGeneralesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  }
  // {
  //   path: '/caja/aperturar-caja',
  //   name: 'aperturarCaja',
  //   component: () => import('../views/app/cajas/AperturaCajaView.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  // {
  //   path: '/caja/detalle',
  //   name: 'detalleCaja',
  //   component: () => import('../views/app/cajas/DetalleCajasView.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  // {
  //   path: '/caja/detalle/transacciones/:id',
  //   name: 'detalleTransacciones',
  //   component: () => import('../views/app/cajas/DetalleTransaccionesView.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let usuario = null;
  await createStore().then((store) => {
    usuario = store.getters.logeado;
  });

  let autorizacion = to.matched.some((record) => record.meta.autenticado);
  if (autorizacion && !usuario) {
    next("/login");
  } else if (!autorizacion && usuario) {
    next("/dashboard");
  } else if (autorizacion && usuario) {
    if (to.meta.middleware) {
      const middleware = to.meta.middleware;
      middleware({ next, to });
    }
    next();
  } else {
    next();
  }
});

export default router
