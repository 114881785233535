
import moment from 'moment'
// import code from './language'
import Vue from 'vue'

// moment.locale(code)
moment.locale('es')

Vue.prototype.$moment = moment

// Formato de fecha standard para cajas de texto pequeñas y dataTables
Vue.filter('fechaStandard', value => moment(value).format('DD-MMMM-YYYY'))

// Formato de fecha standard para cajas de texto pequeñas y dataTables
Vue.filter('fechaStandardCorta', value => moment(value).format('DD-MMM-YYYY'))

// Formato de fecha larga para cajas de texto grandes y con ventana de seleción
Vue.filter('fechaLarga', value => moment(value).format('dddd, DD / MMMM / YYYY'))

// Formato de fecha larga para cajas de texto grandes y con ventana de seleción
Vue.filter('hora', value => moment(value).format('h:mm a'))
