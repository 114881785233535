module.exports = {
  reglas: {
    propias: [
      'at_least_cant_number',
      'at_least_cant_char',
      'less_than',
      'more_than',
      'decimal',
      'validar_cedula_ruc',
      'validar_dato'
    ],
    vee: [
      'required',
      'email',
      'max',
      'confirmed',
      'min',
      'digits',
      'alpha',
      'alpha_spaces',
      'alpha_num',
      'alpha_dash',
      'numeric',
      'is',
      'is_not',
      'image',
      'integer',
      'max_value',
      'min_value',
      'size',
      'mimes',
      'double'
    ]
  }
}
