import Vue from 'vue'
// import * as rules from 'vee-validate/dist/rules'
// import { messages } from 'vee-validate/dist/locale/es.json';
import es from 'vee-validate/dist/locale/es.json';
import * as veeRules from 'vee-validate/dist/rules'

// import * as VeeValidate from 'vee-validate';

import {
  setInteractionMode,
  extend,
  localize,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

// Vue.use(VeeValidate)

import propiasRules from './validacion/extend-vee/reglas-propias'
import { reglas } from './validacion/extend-vee/nombres-reglas'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize('es', es)

// .setMode('passive');
// setInteractionMode('passive')
setInteractionMode('eager')

// const reglas = ['numeric', 'required', 'email', 'min', 'max']

for (const vee of reglas.vee) {
  extend(vee, veeRules[vee])
}

for (const propia of reglas.propias) {
  extend(propia, propiasRules[propia])
}


// Object.keys(rules).forEach(rule => {
//   extend(rule, {
//     ...rules[rule], // copies rule configuration
//     message: messages[rule] // assign message
//   })
// })
