import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore} from "firebase/firestore"

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyCMj8-HTzeP7a_rj6maVqwPYV43fyK7CAo",
  // authDomain: "mini-inv.firebaseapp.com",
  // projectId: "mini-inv",
  // storageBucket: "mini-inv.appspot.com",
  // messagingSenderId: "676530720012",
  // appId: "1:676530720012:web:7f298ec5f5678e4a0a0d29"
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)

// Initialize Auth
const auth = getAuth(firebase)
auth.languageCode = 'es';

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebase);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebase);

export { firebase, auth, db, storage }