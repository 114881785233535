<template>
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.78 169.85">
  <g>
    <path :fill="colorText" d="m252.48,35.96v130.57h-24.23v-87.51h-.17l-60.14,87.51h-29.11L230.51,35.96h21.96Z"/>
    <path :fill="colorText" d="m328.88,46.07c4.42,3.83,7.61,8.31,9.59,13.42,1.98,5.12,2.96,10.63,2.96,16.56s-.99,11.45-2.96,16.56c-1.98,5.12-5.17,9.65-9.59,13.6-2.79,2.44-5.64,4.36-8.54,5.75-2.91,1.39-5.87,2.44-8.89,3.14-3.02.7-6.13,1.13-9.33,1.31-3.2.17-6.6.26-10.2.26h-2.96v-22.84h.52c3.14,0,6.33-.17,9.59-.52,3.25-.35,6.21-1.13,8.89-2.35,2.67-1.22,4.85-2.99,6.54-5.32,1.68-2.32,2.53-5.52,2.53-9.59s-.78-7.12-2.35-9.5c-1.57-2.38-3.63-4.21-6.19-5.49-2.56-1.28-5.4-2.09-8.54-2.44s-6.28-.52-9.41-.52h-8.89v108.43h-24.23V35.43h34.52c3.6,0,7,.09,10.2.26,3.2.17,6.3.61,9.33,1.31,3.02.7,5.98,1.74,8.89,3.14,2.9,1.39,5.75,3.37,8.54,5.93Z"/>
    <path :fill="colorText" d="m416.45,46.07c4.42,3.83,7.61,8.31,9.59,13.42,1.97,5.12,2.96,10.63,2.96,16.56s-.99,11.45-2.96,16.56c-1.98,5.12-5.17,9.65-9.59,13.6-2.79,2.44-5.64,4.36-8.54,5.75-2.91,1.39-5.87,2.44-8.89,3.14-3.02.7-6.13,1.13-9.33,1.31-3.2.17-6.6.26-10.2.26h-2.96v-22.84h.52c3.14,0,6.33-.17,9.59-.52,3.25-.35,6.21-1.13,8.89-2.35,2.67-1.22,4.85-2.99,6.54-5.32,1.68-2.32,2.53-5.52,2.53-9.59s-.79-7.12-2.35-9.5c-1.57-2.38-3.63-4.21-6.19-5.49-2.56-1.28-5.4-2.09-8.54-2.44-3.14-.35-6.28-.52-9.41-.52h-8.89v108.43h-24.23V35.43h34.52c3.6,0,7,.09,10.2.26,3.2.17,6.3.61,9.33,1.31,3.02.7,5.98,1.74,8.89,3.14,2.9,1.39,5.75,3.37,8.54,5.93Z"/>
    <path :fill="colorText" d="m481.3,58.62v85.24h22.49v22.66h-69.03v-22.66h22.49V58.62h-22.49v-22.66h69.03v22.66h-22.49Z"/>
  </g>
  <g>
    <path :fill="colorIcon" d="m177.87,145.78c-.23-.13-.4-.36-.46-.62"/>
    <path :fill="colorIcon" d="m66.03,8.99c-8.07,5.64-12.63,12.56-14.39,15.29-3.21,4.98-4.88,9.27-5.44,10.77-2.95,7.88-3.8,15.85-3.89,24.83-.11,10.73-2.65,20.65-7.63,29.77-7.17,13.12-19.12,21.87-29,31.44-3.37,3.27-6.89,9.23-5.28,13.87,3.53,10.22,25.71,1.21,32.55-.33,8.05-1.81,10.54,5.87,5.82,11.36-4.19,4.88-11.43,10.58-9.8,17.14.06.26.23.49.46.62,9.76,5.69,24.38-.18,32.77-5.89,2.91-1.98,15.99-13.87,19.11-6.76,1.87,4.27-5.7,10.21-8.05,13.37-1.29,1.75-.99,3.14.91,4.19,5.89,3.26,15.28-.85,20.6-3.87,35.53-20.2,55.38-58.65,59.24-98.38,1.16-11.98.41-22.47-3.53-32.2-3.47-8.59-8.31-14.1-9.95-15.91C121.85-2.29,93.4-.02,91.47.16c-4.51.43-15.04,1.57-25.44,8.83Zm72.77,56.47c1.05-7.19,4.45-12.64,7.6-12.18,3.15.46,4.85,6.66,3.8,13.84-1.05,7.19-4.45,12.64-7.6,12.18-3.15-.46-4.85-6.66-3.8-13.84Zm-28.77-4.79c1.47-10.05,8.11-17.4,14.84-16.42,6.74.98,11.01,9.93,9.54,19.98-1.47,10.05-8.11,17.4-14.85,16.42-6.73-.98-11-9.93-9.53-19.98Z"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'AppiLogo',
  props: {
    colorText: {
      type: String,
      require: false,
      default: '#FFFFFF'
    },
    colorIcon: {
      type: String,
      require: false,
      default: '#FFFFFF'
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
