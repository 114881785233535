<template>
  <v-badge
    color="warning"
    offset-x="20"
    offset-y="20"
    class="mr-1"
    :content="stock.length"
    :value="stock.length"
  >
    <template v-slot:badge>
      <span class="small-text">{{ stock.length }}</span>
    </template>
    <v-btn
      icon
      :color="stock.length ? 'donateback' : 'grey'"
      to="/stocks"
      :disabled="!stock.length"
    >
      <v-icon>
        fa-basket-shopping
      </v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import { db } from '@/plugins/firebase'
import { collection, onSnapshot, query, orderBy, where } from "firebase/firestore"

export default {
  name: 'StockBtn',
  data: () => ({
    dataStock: [],
    suscribeStock: null,
    flag: false
  }),
  created () {
    this.getStocks()
  },
  computed: {
    stock() {
      return  this.dataStock.filter(x => x.existencias <= x.existenciaMinima )
    }
  },
  beforeDestroy () {
    if (this.suscribeStock) {
      this.suscribeStock()
    }
  },
  methods: {
    async getStocks() {
      try {
        const queryData = query(collection(db, "inventario"),
          where("controlarExistencia","==", true),
          where("existenciaMinima",">=", 1),
          where("active","==", true),
          orderBy("existenciaMinima"),
          orderBy("nombre"))
        this.dataStock = []
        this.suscribeStock = await onSnapshot(queryData, (querySnapshot) => {
          const stockAux = []
          querySnapshot.forEach((doc) => {
            stockAux.push({id:doc.id, ...doc.data()})
          })
          this.dataStock = stockAux
        },(error) => {
          console.error(error)
        })
      } catch (error) {
        this.dataStock = []
      }
    }
  }
}
</script>
<style>
</style>
