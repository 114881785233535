<template>
  <v-footer
    absolute
    app
    dark
    color="primary"
    height="50"
  >
    <v-row dense class="justify-center">
      <v-col
        class="subtitle-1"
        cols="auto"
      >
        <!-- <v-avatar
          tile
          size="50"
          class="mr-3"
          style="cursor: pointer;"
          @click="toIndex"
        >
          <logo-kirej />
        </v-avatar> -->
        <a class="caption font-weight-light white--text" style="text-decoration:none" to="/" target="_blank">Copyright &copy; {{ (new Date()).getFullYear() }}</a>
        <span class="mx-3">•</span>
        <v-btn
          text
          target="_blank"
          href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*APPI*.%20Mi%20nombre%20es%20"
          style="text-transform:none"
        >
          <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
          0987-679-471
        </v-btn> 
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
// import LogoKirej from '../img/Kirej/LogoBN.vue'
export default {
  name: 'FooterSite',
  // components: {
  //   LogoKirej
  // },
  data: () => ({
  }),
  computed: {
    isMenu () {
      return this.$route.name === '/home'
    },
  },
  methods: {
    toIndex () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
.f-resaltarlink {
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.f-resaltarlink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #ffffff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition-delay: 50ms;
  width: 0;
}
.f-resaltarlink:hover:after {
  transition-delay: 0.2s;
  width: 100%;
  left: 0%;
}
</style>
