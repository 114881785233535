import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { firebase } from './plugins/firebase'
import './plugins/filters'
import VueCountdown from '@chenfengyuan/vue-countdown'
import createStore from './store'
import VueHtml2pdf from 'vue-html2pdf'
import '@fortawesome/fontawesome-free/css/all.css'
import './plugins/vuetify-mask'
import './plugins/apexchart'
// import './plugins/axios'
import './moment'
import './vee-validate'
import 'devextreme/dist/css/dx.light.css';

import './plugins/axios'

Vue.config.productionTip = false
Vue.use(VueHtml2pdf)
Vue.component('vue-countdown', VueCountdown)

createStore().then(store => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  
})

export default { firebase }
