/* eslint-disable no-console */
import { register } from 'register-service-worker'

// console.log('INICIA: ', process.env.NODE_ENV)
if (process.env.VUE_APP_MODE === 'produccion') {
  register(`${process.env.VUE_APP_BASEURL}service-worker.js`, {
    ready () {
      console.log(
        'La aplicación está siendo servida desde la caché por un trabajador del servicio.'
      )
    },
    registered () {
      console.log('El trabajador del servicio se ha registrado.')
    },
    cached () {
      console.log('El contenido se ha almacenado en caché para su uso sin conexión.')
    },
    updatefound () {
      console.log('Se está descargando contenido nuevo.')
    },
    updated (registration) {
      console.log('Hay nuevo contenido disponible; porfavor refresca.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No se encontró conexión a Internet. La aplicación se está ejecutando en modo fuera de línea.')
    },
    error (error) {
      console.error('Error durante el registro del trabajador del servicio:', error)
    }
  })
}