<template>
  <v-badge
    color="success"
    offset-x="20"
    offset-y="20"
    class="mr-1"
    :content="prints.length"
    :value="prints.length"
  >
    <template v-slot:badge>
      <span class="small-text">{{ prints.length }}</span>
    </template>
    <!-- :disabled="!prints.length" -->
    <v-btn
      icon
      :color="prints.length ? 'donateback' : 'grey'"
      to="/prints"
    >
      <v-icon>
        fa-print
      </v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from '@/plugins/firebase'
import { collection, onSnapshot, query, orderBy, where, doc, updateDoc } from "firebase/firestore"
import imprimir from '@/functions/SetPrint'

export default {
  name: 'PrinterBtn',
  data: () => ({
    prints: [],
    suscribePrints: null,
    flag: false
  }),
  created () {
    this.getPrints()
  },
  computed : {
    ...mapGetters(["negocio"]),
  },
  beforeDestroy () {
    if (this.suscribePrints) {
      this.suscribePrints()
    }
  },
  methods: {
    async getPrints() {
      try {
        const queryData = query(collection(db, "prints"),
          where("state","==", 'Nueva'),
          where("deleted","==", false),
          orderBy("date","desc"))
        this.suscribePrints = await onSnapshot(queryData, (querySnapshot) => {
          this.prints = []
          querySnapshot.docChanges().forEach((doc) => {
            if (doc.type === "added") {
              this.prints.push({id:doc.doc.id, ...doc.doc.data()})
            }
          })
          this.sendToPrint(this.prints[0])
        },(error) => {
          console.error('Hay un error en el listinig del onSnapshot')
          console.error(error)
        })
      } catch (error) {
        console.error('error -> ', error.message)
        this.prints = []
      }
    },
    async sendToPrint(data) {
      if (data) {
        let numPrint = 0
        if (data.origin === 'PagoComprobante') {
          data.dataPost.impresora = this.negocio.impresoraTickets
          numPrint = this.negocio.numeroImpresionComprobantes ? this.negocio.numeroImpresionComprobantes : 1
        }
        data.cajonera = {
          abrir:  this.negocio.abrirCajonera ? 'true' : 'false'
        }
        if (numPrint > 0) {
          const promesas = []
          for (let index = 0; index < numPrint; index++) {
            data.dataPost.id+=index.toString();
            promesas.push(imprimir.printDocument(data.service, data.dataPost))
          }
          Promise.all(promesas)
        }
        await updateDoc(doc(db, 'prints', data.id), {
          state: 'Impresa'
        })
      }
    }
  }
}
</script>
<style>
</style>
